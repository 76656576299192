@import './../../styles/variables.module.scss';

.wrapper {
  margin: 0 auto;

  :global([data-hook='site-selector-popover']) {
    max-width: 100%;
  }

  .innerWrapper {
    background-color: #f6f7f9;
    padding: 0 var(--wds-space-800);
    height: calc(100vh - 166px - 83px);
    border-radius: var(--wds-space-500);
    padding-bottom: var(--wds-space-400);

    .leftAndRightSide {
      .leftSide {
        .title {
          @include Header1Bold();
          text-align: left;

          @include rtl() {
            text-align: right;
          }

          @include mobile-only() {
            @include Header2Bold();
          }

          :global(.site-selector-hero-highlight) {
            background-color: #8deace;
            box-sizing: content-box;
            padding: 0 var(--wds-space-100);
            margin: 0 -4px;
          }
        }

        .subtitle {
          padding: var(--wds-space-300) 0 var(--wds-space-700);
          white-space: pre-wrap;
        }
        .searchBar {
          align-items: center;

          .searchInput {
            border-radius: 100px;
            height: var(--wds-space-1000);
            padding-inline-start: var(--wds-space-50);
            margin-inline-end: 8px;
            width: 100%;
          }

          .searchIconAI {
            @include rtl() {
              transform: rotate(180deg);
            }
            width: 100%;
          }

          .searchArrowIcon {
            @include rtl() {
              transform: rotate(180deg);
            }
            height: var(--wds-space-1000);
            width: var(--wds-space-1000);
          }
          .searchArrowIconAI {
            width: var(--wds-space-700);
            height: var(--wds-space-700);
            svg {
              height: var(--wds-space-400);
              width: var(--wds-space-400);
            }
          }
        }

        .badges {
          padding-top: var(--wds-space-300);

          .badgeLink {
            .badge {
              .badgeText {
                color: var(--wds-color-black-100);
              }
            }

            &:hover,
            &:focus {
              .badge {
                background-color: var(--wds-color-blue-500);
              }
            }
          }
        }
      }

      .rightSide {
        .heroImage {
          width: 100%;
          max-width: 530px;
          min-width: 397px;
          height: auto;
        }
      }

      @include small-screen() {
        .rightSide {
          display: none;
        }
      }

      @include mobile-only() {
        .rightSide {
          display: none;
        }
      }
    }
  }

  .exploreMoreContainer {
    justify-self: flex-end;
    position: relative;
    bottom: var(--wds-space-200);

    .exploreMore {
      color: var(--wds-color-blue-50);

      .exploreMoreIcon {
        margin-top: -5px;
        height: var(--wds-space-600);
        width: var(--wds-space-600);
      }

      &:hover,
      &:focus {
        .exploreMoreText {
          text-decoration: underline;
        }

        .exploreMoreIcon {
          animation: bounceY 1s;
        }
      }
    }
  }
}

@keyframes bounceY {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  50% {
    transform: translate3d(0, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(0, 10px, 0);
  }
}
