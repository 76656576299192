@import '../../../styles/variables.module.scss';

.TopicsSectionCardWrapper {
  box-sizing: border-box;
  overflow: hidden;
  flex: 1;
  min-width: 376px;
  max-width: calc(50% - var(--wds-space-500) / 2);

  @include small-screen() {
    min-width: calc(50% - var(--wds-space-500) / 2);
  }

  @include mobile-only() {
    min-width: 100%;
    max-width: 100%;
  }

  .TopicsSectionCardImage {
    height: 212px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    position: relative;
    overflow: hidden;
    img {
      object-fit: cover;
    }
    @include small-screen() {
      height: 189px;
    }

    @include mobile-only() {
      height: 189px;
    }
  }

  .TopicsSectionCardTopicsWrapper {
    .TopicsSectionCardTitleLink,
    .TopicsSectionCardShowAllLink,
    .TopicsSectionCardTopicLink {
      display: flex;
      overflow: hidden;
      box-sizing: border-box;
      max-width: 100%;
      text-decoration: none;
      line-height: 0;
    }

    .TopicsSectionCardTitle {
      @include Header3Bold();
      margin-bottom: var(--wds-space-100) !important;

      &:hover,
      &:focus {
        color: var(--wds-color-blue-100);
      }
    }
    box-sizing: border-box;

    overflow: hidden;
    width: 100%;
    padding: var(--wds-space-300) var(--wds-space-400) var(--wds-space-400);
    border: 1px solid var(--wds-color-black-600);
    border-top: none;
    border-bottom-left-radius: var(--wds-space-300);
    border-bottom-right-radius: var(--wds-space-300);
  }

  .TopicsSectionCardTopics {
    gap: calc(var(--wds-space-100) / 2);
    overflow: hidden;
    box-sizing: border-box;
    height: 78px;
    width: 100%;

    .TopicsSectionCardTopicLink {
      gap: var(--wds-space-100);
      align-items: center;
      .TopicsSectionCardTopicText,
      svg {
        color: var(--wds-color-black-100);
      }
      &:hover,
      &:focus {
        .TopicsSectionCardTopicText,
        svg {
          color: var(--wds-color-blue-100) !important;
        }
      }

      svg {
        flex-shrink: 0;
        @include rtl() {
          transform: scale(-1, 1);
        }
      }
    }

    .TopicsSectionCardTopicText {
      overflow: hidden;
    }
  }

  .TopicsSectionCardShowAllLink {
    margin-top: var(--wds-space-400);
    .TopicsSectionCardShowAllButton {
      @include rtl() {
        svg {
          transform: scale(-1, 1);
          margin: 0 6px 0 -12px;
        }
      }
      svg {
        margin: 0 -12px 0 6px;
      }
    }
  }
}
